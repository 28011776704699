<script setup lang="ts">
import SearchItem from './SearchItem.vue';

defineOptions({
  name: 'SearchPage',
});

const route = useRoute();
const { $gtm, $mopI18n, $urls } = useNuxtApp();
const classNameRef = ref(['']);

const loadingRef = ref({
  loading: true,
});
const hasJobResultRef = ref(false);
const termRef = ref(route.query.q);

const { searchContent, searchResultRef } = useMopSearch();
const { initTransition } = useMopPageTransitionClient();
const { fetchAllJobs, getFilteredJobList } = useMopJob();

initTransition(classNameRef, loadingRef);

onMounted(() => {
  $gtm.trackPageView({
    pageType: 'Search',
  });
});

watch(
  () => route.query.q,
  async (searchTerm) => {
    termRef.value = searchTerm;
    if (searchTerm) {
      loadingRef.value.loading = true;
      await Promise.all([fetchAllJobs(), searchContent(String(searchTerm))]);
      hasJobResultRef.value = getFilteredJobList(String(searchTerm)).length > 0;
    } else {
      hasJobResultRef.value = false;
    }
    loadingRef.value.loading = false;
  },
  { immediate: true },
);

function handleItemClick(item: any) {
  $gtm.trackEvent({
    eventName: 'site_search_clicked',
    parameters: [$gtm.getCurrentPageType(), String(termRef), 'page_results', item.link],
  });
}

function handleJobClick() {
  $gtm.trackEvent({
    eventName: 'site_search_clicked',
    parameters: [
      $gtm.getCurrentPageType(),
      String(termRef),
      'job_results',
      $mopI18n.localePath(`${$urls.CAREER_PAGE}?search=${termRef}`),
    ],
  });
}

useHead({
  title: `${$mopI18n.t('locale.search.title')} ${useRoute().query.q}`,
  meta: [
    {
      hid: 'robots',
      name: 'robots',
      content: 'noindex, nofollow',
    },
  ],
});
</script>

<template>
  <div :class="['search-page', 'transition', ...classNameRef]">
    <MopPageLoad :class="classNameRef" />
    <div class="transition-content">
      <div class="search-page__query-wrap">
        {{ $mopI18n.t('locale.search.title') }}
        <div class="search-page__query">
          {{ termRef }}
        </div>
      </div>
      <div v-if="searchResultRef.length > 0 || hasJobResultRef" class="search-page__result">
        <template v-if="hasJobResultRef">
          <div
            class="search-page__item search-page__item--job"
            :style="{ '--bg-item-color': $mopConfig.getJobSearchBgColor() }"
          >
            <div class="search-page__jobs-found">
              {{ $mopI18n.t('locale.search.jobs_found') }}
            </div>
            <NuxtLink
              class="search-page__jobs-search"
              :to="$mopI18n.localePath(`${$urls.CAREER_PAGE}?search=${termRef}`)"
              :style="{ '--bg-color': $mopConfig.getHeaderJobButtonColor() }"
              no-prefetch
              @click="handleJobClick"
            >
              {{ $mopI18n.t('locale.jobs.search_button') }}
            </NuxtLink>
          </div>
        </template>
        <template v-if="searchResultRef.length > 0">
          <SearchItem
            v-for="item in searchResultRef"
            :key="item.title"
            :title="item.title"
            :description="item.description"
            :link="item.link"
            class="search-page__item"
            @click="handleItemClick(item)"
          />
        </template>
      </div>
      <div v-else-if="!loadingRef.loading && !searchResultRef.length && !hasJobResultRef">
        {{ $mopI18n.t(`locale.search.empty`, [String(termRef || '').toUpperCase()]) }}
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.search-page {
  padding: 0 $global-padding;
}

.search-page__query-wrap {
  @include text-style(strong);

  text-transform: uppercase;
  margin-bottom: $space20;
}

.search-page__query {
  font-size: 40px;
  line-height: 40px;
  text-transform: uppercase;
}

.search-page__item {
  border-bottom: 1px solid $black;
  padding: $space20 0 $space10;
}

.search-page__jobs-search {
  @include text-style(strong);

  background: var(--bg-color);
  color: $white;
  text-transform: uppercase;
  padding: 8px $space15;
  margin-right: $space30;
  align-self: center;
}

.search-page__item--job {
  background: var(--bg-item-color);
  padding: $space20 $space10;
  color: $white;
  margin-right: -#{$global-padding};
  margin-left: -#{$global-padding};
  border-bottom: 0;
}

.search-page__jobs-found {
  @include text-style(strong);

  font-size: 22px;
  line-height: 22px;
  color: $white;
  text-transform: uppercase;
  margin-bottom: $space20;
}
</style>
